import { doc, updateDoc } from "firebase/firestore";
import { db } from "lib/firebase";
import { useState } from "react";
import { toast } from "react-toastify";
import { fetchReport } from "services/ReportService";
import { useUserData } from "./useUser";
import { posthog } from "posthog-js";
import { useRouter } from "next/router";
import GeoNames from "lib/options/GeoNames";
import { Geo } from "../types/MapContext";
import { getStateNameByCode } from "lib/helpers/getArea";

const useDownloadReport = ({ geo, geoCode, stateCode, datapoint }) => {
  const user = useUserData();
  const router = useRouter();
  const [downloading, setDownloading] = useState(false);

  const downloadReport = async (onSuccess?: () => void, values?: any) => {
    try {
      setDownloading(true);
      const reportToken = generateToken();
      await updateDoc(doc(db, "users", user.id as string), { reportToken });
      const locationName = new GeoNames().getFullName(geo, geoCode, stateCode);
      const stateName = extractStateName(geo, stateCode);

      await fetchReport(
        {
          geo: values?.geo || geo,
          datapoint: values?.datapoint || datapoint,
          geoCode: (values?.geoCode || geoCode)?.toString(),
          stateCode: values?.stateCode || stateCode,
          token: reportToken,
        },
        locationName,
      );

      posthog.capture("Download Report", {
        hasPaidToDownload: values?.hasPaidToDownload,
        downloadFrom: router.pathname,
        location: locationName,
        state: stateName,
        platform: "Web",
        activeState: getStateNameByCode(user?.activeState),
        scoreName:
          datapoint === "home_buyer_score" ? "long_term_growth" : datapoint,
      });

      if (onSuccess) onSuccess();
    } catch (error) {
      if (typeof error === "string") {
        toast(error, {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 5000,
          closeOnClick: true,
        });
      }
    } finally {
      setDownloading(false);
    }
  };

  const generateToken = () => {
    return Math.random().toString(36).substr(2);
  };

  return { downloadReport, downloading };
};

const extractStateName = (geo, stateCode) => {
  return new GeoNames().getFullName(Geo.STATE, stateCode, stateCode);
};

export default useDownloadReport;
