import { useTheme } from "@emotion/react";
import { mdiClose } from "@mdi/js";
import Icon from "@mdi/react";
import { Flex } from "components/Flex";
import { Modal } from "components/Modal";
import { Text } from "components/Text";
import ReportsPaymentElement from "./ReportsPaymentElement";
import { StripeElementsOptions } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { getStripePromise } from "lib/helpers/getStripePromise";

const stripePromise = getStripePromise();

export const ReportsCheckout = ({ open, onClose }) => {
  const theme = useTheme();

  const options = {
    paymentMethodTypes: ["card"],
    fonts: [
      {
        family: "Lato",
        cssSrc:
          "https://fonts.googleapis.com/css2?family=Lato:wght@300;400;500;600;700&display=swap",
      },
    ],
    mode: "subscription",
    setup_future_usage: "off_session",
    amount: 1900,
    currency: "usd",
    // Fully customizable with appearance API.
    appearance: {
      variables: {
        fontSizeSm: "16px",
        fontFamily: "Lato",
      },
      rules: {
        ".Input": {
          padding: "12px 16px",
          borderRadius: `${theme.radii.small}`,
          fontSize: `${theme.fontSizes.default}px`,
          outline: "none",
          border: `1px solid ${theme.colors.inputBorder}`,
        },
        ".Input:focus": {
          outline: "none",
          border: `1px solid ${theme.colors.inputBorder}`,
          boxShadow: "none",
        },
        ".Label": {
          fontWeight: "700",
          margin: "8px 0",
        },
      },
    },
  } as StripeElementsOptions;

  return (
    <>
      <Modal
        isOpen={open}
        contentStyle={{
          maxWidth: 330,
          background: "white",
          width: "100%",
          padding: 0,
        }}
      >
        <Flex
          justify="space-between"
          css={{ padding: "14px 22px" }}
          align="center"
        >
          <Text tagName="h1" fontSize="large" bolder>
            Download Report
          </Text>
          <Flex
            justify="center"
            align="center"
            css={{
              borderRadius: theme.radii.smaller,
              background: theme.colors.lightBg,
              height: 30,
              width: 30,
              cursor: "pointer",
            }}
            onClick={() => onClose()}
          >
            <Icon path={mdiClose} color={theme.colors.gray200} size="18px" />
          </Flex>
        </Flex>
        <div
          css={{
            borderTop: `1px solid ${theme.colors.radioBorder}`,
            width: "100%",
          }}
        />
        <div css={{ padding: "14px 22px" }}>
          <Elements stripe={stripePromise as any} options={options}>
            <ReportsPaymentElement onClose={onClose} />
          </Elements>
        </div>
      </Modal>
    </>
  );
};
